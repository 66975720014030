import React from "react";
import { container, content, smallText } from "./index.module.less";
import qr_code from "images/qrcode.jpg";
import Layout from "components/shared/Layout";

export default () => (
  <div className={container}>
    <Layout>
      <div className={[content]}>
        <a target={"_blank"} href={"http://weixin.qq.com/r/XRDSytHE9IyLrZWp90VP"}><img src={qr_code} alt="QR-Code" /></a>
        <p>集思社公众号</p>
      </div>
    </Layout>
  </div>
);
