import React from "react";
import { container, content, smallText } from "./index.module.less";
import Layout from "components/shared/Layout";

export default ({ children }) => (
  <header className={container}>
    <Layout>
      <div className={[content]}>
        {/* <h1>
          China 
            <span style={{color:"#fbd34c"}}>Healthy Ageing</span> Project
        </h1> */}
        <h1>
          中英
          <span style={{color:"#fbd34c"}}>健康老龄化</span> 
          项目
        </h1>
        <p>利用全球智慧推动创新，帮助中国和英国的老年人更长久地过上充实，健康和舒适的生活。</p>
      </div>
    </Layout>
  </header>
);
