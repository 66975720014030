import React from "react";
import Header from "components/Header";
import QRCodeSection from "components/QRCodeSection";
import Intro from "components/Intro";
import StoriesGrid from "components/StoriesGrid";
import Contact from "components/Contact";
import Footer from "components/shared/Footer";
import Logos from "components/Logos";
import Nav from "components/shared/Nav";
import Meta from "components/shared/Meta";
import Layout from "components/shared/Layout";
import { intro } from "./index.module.less";
import { Link } from "gatsby";

import { graphql } from "gatsby";

export default (queryResults) => {
  return (
    <>
      <Nav activeSection={"Home"}/>
      <Meta url="/" />
      <Header></Header>
      <QRCodeSection></QRCodeSection>
      <Intro></Intro>
      <StoriesGrid blogPosts={queryResults.data.allWpPost.nodes} maxCardsCount={3}></StoriesGrid>
      <Contact></Contact>
      <Logos></Logos>
      <Footer />
    </>
  );
};

export const pageQuery = graphql`
query {
  allWpPost (
    sort: { fields: date, order: DESC }
  ){
      nodes {
          id
          title
          content
          uri
          featuredImage {
              node {
                  sourceUrl
                  localFile {
                      publicURL
                      childImageSharp {
                          gatsbyImageData(
                              width: 1100
                              placeholder: BLURRED
                              formats: [AUTO, WEBP, AVIF]
                          )
                      }
                  }
              }
          }
      }
  }
}`;